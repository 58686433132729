
import { defineComponent, provide, toRefs } from "vue";

export default defineComponent({
  name: "ButtonRadioGroup",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const { modelValue } = toRefs(props);
    provide("modelValue", modelValue);

    const onChecked = (val: string) => {
      context.emit("update:modelValue", val);
    };
    provide("onChecked", onChecked);

    return {};
  },
});
