
import { defineComponent, inject, ref, watch, computed } from "vue";
import type { Ref } from "vue";
import { dateFormat } from "@/utils";

export default defineComponent({
  name: "DateButtonRadio",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const modelValue = inject<Ref<string>>("modelValue");
    const onChecked = inject<any>("onChecked");
    const nowDate = new Date();
    const startTime = ref<Date>();
    const endTime = ref<Date>();
    const currentDate = ref<Date>();
    const show = ref(false);
    const dateType = ref("start");

    // computed max min
    const minTime = computed(() => {
      if (dateType.value === "start") {
        return undefined;
      }
      return startTime.value;
    });
    const maxTime = computed(() => {
      if (dateType.value === "start") {
        return endTime.value || nowDate;
      }
      return nowDate;
    });
    const startTimeText = computed(() => {
      if (startTime.value) {
        return dateFormat(startTime.value, "YYYY-MM-DD");
      }
      return "开始时间";
    });
    const endTimeText = computed(() => {
      if (endTime.value) {
        return dateFormat(endTime.value, "YYYY-MM-DD");
      }
      return "结束时间";
    });

    watch(
      () => modelValue?.value,
      (val) => {
        if (val !== "0") {
          startTime.value = undefined;
          endTime.value = undefined;
        }
      }
    );

    const setStartTime = (val: string) => {
      if (!startTime.value) {
        startTime.value = nowDate;
        context.emit("change", [startTime.value, endTime.value]);
      }
      currentDate.value = startTime.value;
      dateType.value = "start";
      show.value = true;
      onChecked(val);
    };
    const setEndTime = (val: string) => {
      if (!endTime.value) {
        endTime.value = nowDate;
        context.emit("change", [startTime.value, endTime.value]);
      }
      currentDate.value = endTime.value;
      dateType.value = "end";
      show.value = true;
      onChecked(val);
    };
    const onCancel = () => {
      show.value = false;
      currentDate.value = undefined;
    };
    const onConfirm = () => {
      if (dateType.value === "start") {
        startTime.value = currentDate.value;
      } else {
        endTime.value = currentDate.value;
      }
      context.emit("change", [startTime.value, endTime.value]);
      onCancel();
    };
    return {
      modelValue,
      startTime,
      endTime,
      currentDate,
      minTime,
      maxTime,
      show,
      startTimeText,
      endTimeText,
      setStartTime,
      setEndTime,
      onCancel,
      onConfirm,
    };
  },
});
