import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-634053f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "proc-comps-radio-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'proc-comps-radio__button',
        { 'proc-comps-radio__button-active': _ctx.modelValue === _ctx.label },
      ]),
      onTouchend: _cache[0] || (_cache[0] = () => _ctx.touchend(_ctx.label))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 34)
  ]))
}