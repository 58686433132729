
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "ButtonRadioItem",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  setup() {
    const modelValue = inject("modelValue");
    const onChecked = inject<any>("onChecked");
    const touchend = (val: string) => {
      onChecked(val);
    };
    return { modelValue, touchend };
  },
});
