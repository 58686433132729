
import { defineComponent, ref, reactive, toRefs } from "vue";
import ProcessType from "@/enums/processType";
import {
  ButtonRadioItem,
  ButtonRadioGroup,
  DateButtonRadio,
} from "@/components/ButtonRadio";
import { dateFormat, getDay } from "@/utils";

interface SearchProps {
  queryStatusCode?: ProcessType;
  queryStartDate?: string;
  queryEndDate?: string;
}

export default defineComponent({
  name: "TaskFilter",
  emits: ["filter", "update:modelValue"],
  components: {
    ButtonRadioItem,
    ButtonRadioGroup,
    DateButtonRadio,
  },
  props: {
    hideState: {
      type: Boolean,
      default: false,
    },
    defaultTime: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const { defaultTime } = toRefs(props);
    const showFilter = ref(false);
    const timeVal = ref(defaultTime.value);
    const stateVal = ref("1");
    const searchVal = reactive<SearchProps>({
      queryStartDate: "",
      queryEndDate: "",
    });

    const onReset = () => {
      timeVal.value = "";
      stateVal.value = "1";
      searchVal.queryStatusCode = ProcessType.APPROVE;
      searchVal.queryStartDate = "";
      searchVal.queryEndDate = "";
    };
    const onFilter = () => {
      if (timeVal.value && timeVal.value !== "0") {
        searchVal.queryEndDate =
          dateFormat(new Date(), "YYYY-MM-DD") + " 23:59:59";
      }
      if (timeVal.value === "1") {
        searchVal.queryStartDate = getDay(-7) + " 00:00:00";
      }
      if (timeVal.value === "2") {
        searchVal.queryStartDate = getDay(-30) + " 00:00:00";
      }
      if (timeVal.value === "3") {
        searchVal.queryStartDate = getDay(-90) + " 00:00:00";
      }
      if (stateVal.value === "all") {
        delete searchVal.queryStatusCode;
      }
      if (stateVal.value === "0") {
        searchVal.queryStatusCode = ProcessType.DRAFT;
      }
      if (stateVal.value === "1") {
        searchVal.queryStatusCode = ProcessType.APPROVE;
      }
      if (stateVal.value === "2") {
        searchVal.queryStatusCode = ProcessType.REJECT;
      }
      if (stateVal.value === "4") {
        searchVal.queryStatusCode = ProcessType.CANCEL;
      }
      if (stateVal.value === "5") {
        searchVal.queryStatusCode = ProcessType.COMPLETE;
      }
      context.emit("filter", searchVal, timeVal.value);
      showFilter.value = false;
    };
    const updateTime = ([start, end]: [Date, Date]) => {
      if (start) {
        searchVal.queryStartDate =
          dateFormat(start, "YYYY-MM-DD") + " 00:00:00";
      }
      if (end) {
        searchVal.queryEndDate = dateFormat(end, "YYYY-MM-DD") + " 23:59:59";
      }
    };

    if (defaultTime.value) {
      onFilter();
    }

    return {
      showFilter,
      timeVal,
      stateVal,
      onReset,
      onFilter,
      updateTime,
    };
  },
});
